<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<a-table :columns="columns" :dataSource="questionnairesList" rowKey="id">
			<div slot="description" slot-scope="record">
				{{ record.description | replaceIfLogicalFalse('-') }}
			</div>
			<div slot="action" slot-scope="record">
				<a-button type="info" size="small" icon="edit" @click="onEditQuotation(record.id)" />
			</div>
		</a-table>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'QuestionnairesListComponent',
	computed: {
		...mapGetters('questionnaires', ['spinnerLoaderLabel', 'spinnerLoader', 'questionnairesList']),
	},
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Nombre',
					dataIndex: 'name',
					key: 'name',
					align: 'center',
				},
				{
					title: 'Descripción',
					scopedSlots: { customRender: 'description' },
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '10%',
				},
			],
		}
	},
	mounted() {
		this.$store.dispatch('questionnaires/GET')
	},
	methods: {
		onEditQuotation(id) {
			this.$store.dispatch('questionnaires/GET_ONE', id).then((response) => {
				this.$router.replace(`/team/questionnaires/${id}`)
			})
		},
	},
}
</script>
