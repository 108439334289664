<template>
	<div>
		<div class="row" v-if="false">
			<div class="col-md-12 text-right">
				<router-link :to="`/quotations/new`">
					<a-button class="btn btn-info" icon="plus" lin>Nuevo cuestionario</a-button>
				</router-link>
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				<QuestionnairesList />
			</div>
		</div>
	</div>
</template>

<script>
//
import QuestionnairesList from '@/components/questionnaires/list'

export default {
	name: 'QuestionnairesView',
	components: {
		QuestionnairesList,
	},
}
</script>
